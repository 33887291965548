/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.fuse-text-color {
    color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.fuse-bg-color {
    background-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.fuse-border-color-1 {
    border: 1px solid rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.fuse-border-color-2 {
    border: 2px solid rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.fuse-border-color-right-1 {
    border-right: 1px solid rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.fuse-border-color-left-1 {
    border-left: 1px solid rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.fuse-border-color-bottom-1 {
    border-bottom: 1px solid rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

.mat-tab-body-content {
	padding: 0 !important;
}

html {
 	scroll-behavior: smooth;
}

.dt-button {
    background-color: var(--fuse-primary)  !important;
    color: var(--fuse-on-primary)  !important;
    border-radius: 9999px !important;
    border: 0px !important;
    font-family: "Inter var", ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"  !important;

    font-size: 0.875rem  !important;
    font-weight: 500  !important;
}

/* TABLE */
/*
tbody > .odd, .even {
    display: none !important;
}

table.dataTable thead > tr > th.sorting {
    padding-right: 2rem !important;    
}

table.dataTable thead th {
    padding: 0px !important;
}

tbody tr:first-child td {
    border-top: 1px solid rgba(0, 0, 0, 0.15) !important;    
}
*/

table.dataTable thead > tr > th.sorting {
    padding-right: 2rem !important;    
}

table.dataTable thead th {
    padding: 0px !important;
}

tbody tr:first-child td {
    border-top: 1px solid rgba(0, 0, 0, 0.15) !important;    
}

.mat-table-bg-none {
    background: none !important;
}

.mat-table-last-border-none {
    border: none !important;
}
/* TABLE */




#calendar > .mat-form-field-wrapper {
    margin-bottom: 0 !important;
}

#mb-clear > .mat-form-field-wrapper {
    margin-bottom: 0 !important;
}


#m-0 > .mat-form-field-wrapper {
    margin-bottom: 0 !important;
}

.hover-shadow:hover {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    cursor: pointer;
}


.mat-horizontal-content-container {
    padding: 0 !important;   
}

#stepper-no-background > .mat-horizontal-stepper-wrapper > .mat-horizontal-stepper-header-container {
    border-radius: 0.75rem !important;
    overflow: hidden !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity)) !important;
}

mat-snack-bar-container {
    border-left: 5px solid rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
    background-color: rgba(var(--fuse-bg-card-rgb),var(--tw-bg-opacity));
    --tw-bg-opacity: 1;
    border-radius: 3px;
    font-size: 0.75rem !important;
    color: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity)) !important;
}


.mdc-snackbar__surface {
    border-radius: 3px !important;

}

.mdc-button__label {
    color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

/* Onaymatik P SVG Loading Animasyon */
path#logop{
    fill: none;
    stroke-linecap: round;
    stroke: rgba(var(--fuse-primary-rgb)) !important;
    stroke-width: 5px;
    stroke-dasharray: 220 280;
    animation: pathdraw 2.0s ease-in-out infinite;
}
polyline#lineone{
    fill: none;
    stroke-linecap: round;
    stroke: rgba(var(--fuse-primary-rgb)) !important;
    stroke-width: 5px;
    stroke-dasharray: 40 60;
    animation: linedraw1 2.0s ease-in-out infinite;

}
polyline#linetwo{
    fill: none;
    stroke-linecap: round;
    stroke: rgba(var(--fuse-primary-rgb)) !important;
    stroke-width: 5px;
    stroke-dasharray: 40 110;
    animation: linedraw2 2.0s ease-in-out infinite;
}



.onaymatik-logo-animation {
    animation: pulse 1s infinite ease-in-out alternate, flash 1s ease infinite alternate;
}

@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}

@keyframes flash {
    from { opacity: 0; }	
    to { opacity: 1; }
}


@keyframes pathdraw {
    0%{
        stroke-dashoffset: 0;
    }
    100%{
        stroke-dashoffset: -500;
    }
}

@keyframes linedraw1 {
    0%{
        stroke-dashoffset: 0;
    }
    100%{
        stroke-dashoffset: -100;
    }
}

@keyframes linedraw2 {
    0%{
        stroke-dashoffset: 0;
    }
    100%{
        stroke-dashoffset: -150;
    }
}


.mat-step-header {
    pointer-events: none !important;
}


.mdc-tab {
    padding: 0 !important;
    font-size: 0.75rem !important;
    color: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity)) !important;
    --tw-bg-opacity: 1;
}

.mdc-tab--active {
    color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}

/* Select2 saga kayma hatasinin giderilmesi */
.select2-container {
    display: block !important;
}

.mat-expansion-panel-body-p-0 {
    .mat-expansion-panel-body {
        padding: 0 !important;
    }
}

/* toast-service x renk */
.mat-simple-snackbar-action {
    .mat-focus-indicator {
        .mat-button-wrapper {
            color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
        }
    }
}

.labelInput>div>div>div {
    display: grid !important;
}





/* ===== Tablo siralama cubuklarini gizleme (sadece sirlanan sutunda gosterilir) CSS ===== */
table.dataTable thead > tr > th.sorting:before {
    display: none;
}

table.dataTable thead > tr > th.sorting:after {
    display: none;
}

table.dataTable thead > tr > th.sorting_asc:before {
    display: block;
}

table.dataTable thead > tr > th.sorting_desc:after {
    display: block;
}
/* ===== Tablo siralama cubuklarini gizleme (sadece sirlanan sutunda gosterilir) CSS ===== */




/* ===== Tablo datatable ===== */
table[dataTable] {
    width: 100% !important;
    margin-top: 3rem !important;
}
/* ===== Tablo datatable ===== */




/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity));
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
    border-radius: 24px;
    -webkit-border-radius: 10px;
    border: 3px solid rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity));
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .custom-bg-default-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
  }

  /* Chrome, Edge, and Safari */
  .custom-bg-default-scrollbar::-webkit-scrollbar {
    width: 12px;
  }

  .custom-bg-default-scrollbar::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: rgba(var(--fuse-bg-default-rgb), var(--tw-bg-opacity));
  }

  .custom-bg-default-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
    border-radius: 24px;
    -webkit-border-radius: 10px;
    border: 3px solid rgba(var(--fuse-bg-default-rgb), var(--tw-bg-opacity));
  }

  .from-bg-primary {
    --tw-gradient-from:  rgba(var(--fuse-primary-rgb)) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(99 102 241 / 0)) !important;
}


.custom-snackbar {
    background: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity)) !important;
    color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    border-left-width: 4px !important;
    border-color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
    padding: 0 !important;
}

#date-range-selector > .mat-form-field-wrapper {
    margin-bottom: 0px !important ;
}


.cdk-global-overlay-wrapper {
    overflow-y: hidden !important;
}

.invoiceBasketClass {
    max-height: 90vh !important;
    overflow-y: auto !important;

    .mat-dialog-container {
        padding: 0.5rem !important;
    }
}

.max-height-100 {
    max-height: 100vh !important;
}




table.dataTable tbody > tr > td > ul.dtr-details {
    width: 100%;
}

table.dataTable tbody > tr > td > ul.dtr-details > li > span.dtr-title{
    width: 13.5%;
    display: flex;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

table.dataTable tbody > tr > td > ul.dtr-details > li > span.dtr-data{
    display: flex;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

table.dataTable tbody > tr > td > ul.dtr-details > li{
    display: flex;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before {
    font-family: "Material Icons";
    content: "\e316";
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
    font-family: "Material Icons";
    content: "\e313";
    font-size: 2em;
    left: 0px;
    height: 1em;
    width: 1em;
    margin-top: 0;
    position: absolute;

    top: '';
    display: block;
    color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;;
    border: none;
    border-radius: 0;
    box-shadow: none;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    line-height: 0;
    background-color: transparent !important;
}


.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels {
    justify-content: center;
}

.mat-tab-group .mat-tab-body-content {
    padding: 24px 0 0 0;
}

.mat-tab-group .mat-tab-header .mat-tab-label-container {
    padding: 0 !important;
    margin-bottom: 35px !important;
}

.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
    border-radius: 5px 5px 0px 0px !important;
    color: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity)) !important;
    border-bottom: 2px solid rgba(var(--fuse-primary-rgb), 0.1) !important
}

.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    background-color: rgba(var(--fuse-primary-rgb), 0.1) !important;
    color: rgba(var(--fuse-primary-rgb)) !important;
    border: 2px solid rgba(var(--fuse-primary-rgb)) !important;
    
    // color: white !important;
    // border: 2px solid linear-gradient(to right, rgba(var(--fuse-secondary-rgb)), rgba(var(--fuse-primary-rgb))) !important;
    // background: linear-gradient(to right, rgba(var(--fuse-primary-rgb)), rgba(var(--fuse-primary-rgb), 0.4));
}

.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels>div[role=tab] {
    width: 50% !important;
    border-radius: 0 !important;
}

.dt-button-collection {
    overflow-y: auto !important;
    height: 80%;
}

.dt-button-collection div[role=menu] {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.dt-button-collection div[role=menu] button:not(:first-child) {
    margin-top: 0.5em;
}